import React from 'react';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import isEmpty from "is-empty";
import {styled} from '@mui/material/styles';
import Rating from "@mui/material/Rating";
import withStore from "@/hocs/withStore";
import PriceMinimum from "@/Pages/All/PriceMinimum";
import LinkLoad from "@/Layouts/LinkLoad";
import {isDesktop, isMobile} from "@/Helpers/Function";
import LazyLoad from 'react-lazyload';
import Svg from "@/Pages/All/Svg";

const FavouritesHeart = () => (
    <Svg id={`favouritesHeart`} className={`svg__favouritesHeart`} />
);

const FavouritesHeartActive = () => (
    <Svg id={`favouritesHeartActive`} className={`svg__favouritesHeartActive`} />
);

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#006856',
    },
    '& .MuiRating-iconHover': {
        color: '#006856',
    },
});

function handleItemClick(filterStore) {
    filterStore.scrollPositionChange(window.pageYOffset)
}

function CardAll({index = 0, ...props}) {
    const {el = {}, className, config = {}, stores = {}, authCheck = false} = props;
    const {favouritesStore = {}, filterStore = {}, allStore = {}} = stores;
    const {defaultImage = {}} = config;

    let cardClassName = className ?? '';
    let imgWebp = '', img = '';
    if (!isEmpty(el.img)) {
        img = el.img;
        if (el.img.includes('png')) {
            imgWebp = el.img.replace('png', 'webp');
        }
        if (el.img.includes('jpg')) {
            imgWebp = el.img.replace('jpg', 'webp');
        }
        if (el.img.includes('jpeg')) {
            imgWebp = el.img.replace('jpeg', 'webp');
        }
    } else {
        img = defaultImage.previewPath;
        imgWebp = defaultImage.previewPathWebp;
    }

    let ratingAVG = 0;

    if (!isEmpty(el.ratingAVG)) {
        ratingAVG = Number(el.ratingAVG)
    }

    const cardPrice = (el) => {
        if (el.card_price !== el.price) {
            return <div className={`price`}>
                <span className={`price__current`}>{Number(el.card_price).toLocaleString('ru')} ₽</span>
                <div className={`price__old_discount`}>
                    <span className={`price__old`}>{Number(el.price).toLocaleString('ru')} ₽</span>
                    {el.card_discount !== null && (
                        <div className={`discountMarker`}
                             style={{
                                 color: `${el.marker_discount_color}`,
                                 backgroundColor: `${el.marker_discount_bg_color}`
                             }}
                        >
                            {`-${el.card_discount}%`}
                        </div>
                    )}
                </div>
            </div>
        } else if (el.card_price === null) {
            return <div className={`price`}>
                <span className={`price__current`}>Предзаказ</span>
            </div>
        } else {
            return <div className={`price`}>
                <span className={`price__current`}>{Number(el.card_price).toLocaleString('ru')} ₽</span>
            </div>
        }
    };

    const cardButtons = ({...props}) => {
        const {stores, el, optionalClass = ''} = props;
        const {productDetailStore = {}, quickViewStore = {}, profileStore = {}, basketStore = {}} = stores;

        // Если продукт не доступен
        if (el.status === null) {
            return <div className={`btn btn__white btn__disabled btn__out-stock`}>Не доступно</div>
        }

        // Если нет продукта
        if (el.card_price === null) {
            return <>
                <button className={`btn btn__addWaitList`} onClick={() => profileStore.getSizeWaitList(el.id)}>
                    В лист ожидания
                </button>
                {isDesktop() && (
                    <button className={`btn btn__quick-view`} onClick={() => quickViewStore.openModal(el.article)}>
                        <Svg id={`quick-view`} className={`svg__quickView`} />
                    </button>
                )}
            </>
        } else {
            return <>
                <button
                    className={`btn btn__addCartCatalog ${optionalClass}`}
                    onClick={async () => {
                        await productDetailStore.addBasketCard(el.id, el.article)
                    }}
                >
                    {basketStore.basketProducts.some((ls) => ls.idProduct === el.id) ?
                        "В корзине" : "В корзину"
                    }
                </button>
                {isDesktop() && (
                    <button className={`btn btn__quick-view`} onClick={() => quickViewStore.openModal(el.article)}>
                        <Svg id={`quick-view`} className={`svg__quickView`} />
                    </button>
                )}
            </>
        }
    };

    const YandexContainer = () => {
        const style = {
            display: 'flex',
            flexDirection: 'column',
            // alignItems: 'center',
            gap: '8px',
            marginBottom: '12px',
        };

        let bnplSize = 'l', cashbackSize = 'm';
        if (isMobile()) {
            bnplSize = 'm';
            cashbackSize = 's';
        }

        return <div style={style}>
            <yandex-pay-badge
                type={`bnpl`}
                amount={el.price_split_full}
                size={bnplSize}
                variant={`simple`}
                theme={`light`}
                merchant-id={`a8094f84-fddd-47df-a6af-28faabbb9e6c`}
            />
            <yandex-pay-badge
                type={`cashback`}
                amount={el.price_split_full}
                size={cashbackSize}
                variant={`default`}
                theme={`light`}
                merchant-id={`a8094f84-fddd-47df-a6af-28faabbb9e6c`}
            />
        </div>
    }

    const cardBody = () => {
        return <>
            {el.status !== null && (
                <>
                    <div className={`item-markers`}>
                        {el.sticker && (
                            <img src={el.sticker} alt={el.sticker} className={`sticker`} />
                        )}
                        {/*{newCard(el)}*/}
                        {/*{hitCard(el)}*/}
                    </div>
                    <div className={`item-heart`}>
                        <FormControlLabel
                            control={<Checkbox
                                onClick={(e) => favouritesStore.favouritesChange(e, el, authCheck)}
                                checked={favouritesStore.favouritesData.some((som) => Number(som.id) === Number(el.id))}
                                icon={<FavouritesHeart />}
                                checkedIcon={<FavouritesHeartActive />}
                                name="favoritesHeart" />}
                        />
                    </div>
                </>
            )}
            <LinkLoad href={`/catalog/product/${el.article}`}
                      title={el.h1 + ' ' + el.article}
                      onClick={() => handleItemClick(filterStore)}
                      className="image"
            >
                <div className="box-img mb8">
                    <picture>
                        <source
                            className="increase"
                            type="image/webp"
                            srcSet={imgWebp}
                        />
                        <img
                            className="increase"
                            width="290"
                            height="290"
                            loading="lazy"
                            src={img}
                            alt={el.h1 + ' ' + el.article}
                            title={el.h1 + ' ' + el.article}
                        />
                    </picture>
                </div>
            </LinkLoad>

            <div className={`cardCatalog__item_content`}>
                <PriceMinimum {...props} />
                {cardPrice(el)}
                <YandexContainer />
                <LinkLoad
                    href={`/catalog/product/${el.article}`}
                    className="cardCatalogName"
                    onClick={() => handleItemClick(filterStore)}
                >
                    {el.h1}
                </LinkLoad>
                {el.ratingCount !== null && (
                    <div className="star">
                        <StyledRating
                            name="half-rating-read"
                            value={ratingAVG}
                            precision={0.5}
                            readOnly
                        />
                        <span className="star__feedback">{el.ratingCount}</span>
                    </div>
                )}
            </div>
        </>
    };

    return (
        <div className={`cardCatalog ${cardClassName}`}>
            <div className="cardCatalog__item">
                {allStore.interactionFlag ? (
                    <>
                        {cardBody()}
                        <div className="cardCatalog__item_buttons">
                            {cardButtons(props)}
                        </div>
                    </>
                ) : (
                    <LazyLoad throttle={200} offset={1700}>
                        {cardBody()}
                        <div className="cardCatalog__item_buttons">
                            {cardButtons(props)}
                        </div>
                    </LazyLoad>
                )}
            </div>
        </div>
    );
}

// function newCard(el) {
//     try {
//         if (el.new) {
//             return <div className={`new`}>New</div>;
//         }
//         return '';
//     } catch (e) {
//         console.log('e', e);
//     }
// }
//
// function hitCard(el) {
//     try {
//         if (el.hit) {
//             return <div className={`hit`}>Hit</div>;
//         }
//         return '';
//     } catch (e) {
//         console.log('e', e);
//     }
// }

export default withStore(CardAll);
